<template>
  <v-container>
    <v-flex>
      <h3>Docentes</h3>
    </v-flex>

    <v-flex row xs12 md3 justify-start mb-3 pl-3>
      <v-text-field v-model="search" append-icon="search" label="Buscar docente"></v-text-field>
    </v-flex>
    <v-flex>
      <v-data-table
        :headers="headers"
        :items="localGeneralFaculty"
        :items-per-page="localGeneralFaculty.length"
        :search="search"
        hide-default-footer
        no-data-text="Ainda foram encontrados docentes"
        :loading="showLoading"
        loading-text="Buscando docentes... pode demorar alguns segundos"
      >
        <template v-slot:item="props">
          <tr @click="goFacultyGuidance(props.item)">
            <td class="text-xs-left">{{ props.item.academicCode }}</td>
            <td class="text-xs-left">{{ props.item.name }}</td>
            <td class="text-xs-left">{{ props.item.department }}</td>
            <td class="text-xs-left">{{ props.item.guidances }}</td>
            <td class="text-xs-left">{{ props.item.coGuidances }}</td>
            <td class="text-xs-left">{{ props.item.total }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      localGeneralFaculty: [],
      showLoading: false,
      search: "",
      headers: [
        {
          sortable: true,
          text: "Matrícula",
          value: "academicCode",
          align: "left"
        },
        { sortable: true, text: "Nome", value: "name", align: "left" },
        {
          sortable: true,
          text: "Departamento",
          value: "department",
          align: "left"
        },
        {
          sortable: true,
          text: "Orientandos",
          value: "guidances",
          align: "left"
        },
        {
          sortable: true,
          text: "Co-orientandos",
          value: "coGuidances",
          align: "left"
        },
        {
          sortable: true,
          text: "Total",
          value: "total",
          align: "left"
        }
      ]
    };
  },

  created() {
     this.showLoading = true;
    if (this.generalFaculty.length === 0) {     
      this.getAsyncGeneralFaculty();
    } else {
      this.refreshGeneralFaculty(this.generalFaculty);
    }
  },

  computed: {
    ...mapState("generalFaculty", ["generalFaculty"])
  },

  methods: {
    ...mapActions("generalFaculty", ["ActionGetGeneralFaculty"]),

    async getAsyncGeneralFaculty() {
      try {
        await this.ActionGetGeneralFaculty();
      } catch (err) {
        console.error("Erro ao buscar os docentes:", err);
      }
    },

    refreshGeneralFaculty(docentes) {
      docentes.map(docente => {
        this.localGeneralFaculty.push({
          academicCode: docente.academicCode,
          name: docente.name,
          department: docente.department,
          guidances: docente.guidances,
          coGuidances: docente.coGuidances,
          total: docente.guidances + docente.coGuidances
        });
      });

      this.showLoading = false;
    },

    goFacultyGuidance(docente){
      this.$router.push({ name: 'FacultyProfilePage' , params: { academicCode: docente.academicCode, docente: docente }});
    }
  },

  watch: {
    generalFaculty(newValue) {
      this.localGeneralFaculty = [];
      this.refreshGeneralFaculty(newValue);
    }
  }
};
</script>

<style scoped>
td {
  cursor: pointer;
}
</style>