var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-flex", [_c("h3", [_vm._v("Docentes")])]),
      _c(
        "v-flex",
        {
          attrs: {
            row: "",
            xs12: "",
            md3: "",
            "justify-start": "",
            "mb-3": "",
            "pl-3": ""
          }
        },
        [
          _c("v-text-field", {
            attrs: { "append-icon": "search", label: "Buscar docente" },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c(
        "v-flex",
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.localGeneralFaculty,
              "items-per-page": _vm.localGeneralFaculty.length,
              search: _vm.search,
              "hide-default-footer": "",
              "no-data-text": "Ainda foram encontrados docentes",
              loading: _vm.showLoading,
              "loading-text":
                "Buscando docentes... pode demorar alguns segundos"
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(props) {
                  return [
                    _c(
                      "tr",
                      {
                        on: {
                          click: function($event) {
                            return _vm.goFacultyGuidance(props.item)
                          }
                        }
                      },
                      [
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.academicCode))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.name))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.department))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.guidances))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.coGuidances))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.total))
                        ])
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }